import { CourtFormat } from "./types";
import { generateAppellateCourtFormats } from "./courtsAPI";

function Home() {
    return (
        <AppellateCourtFormats />
    );
}

export default Home;

function FormattingTile({data} : {data: CourtFormat}) {
    return (
        <li className="appellate-courts-li">
            <h2 className="appellate-li-title">{data.title}</h2>
            {/* <p className="appellate-li-desc">{data.description}</p> */}
            <img className="appellate-li-map" src={data.img_url}/>
            <a href={data.url}
               target="_blank"
               rel="noopener noreferrer"
               className="appellate-li-brief-url"
               >
            Formatting Rules for Submissions
            </a>
            {(data.districts.length > 0) && 
            <div className="appellate-districts-container">
                <h4 className="appellate-li-title">Districts</h4>
                    <p className="appellate-li-districts">
                        {data.districts.map((d, index) => {
                            return <span key={`district-${index}`}>{ (index ? ', ' : '') + d.name }</span>;
                        })}
                    </p>
            </div>
            }
        </li>
    );
}

function AppellateCourtFormats() {
    const formats = generateAppellateCourtFormats();
    return (
        <div>
            <InfoBox />
            {/* <SearchBox /> */}
            
            <ul className="appellate-courts-ul">    
                {formats.map((format, index) => {
                    return <FormattingTile data={format} key={`ap-${index}`}/>
                })
                }
            </ul>
        </div>
    );
}

function InfoBox() {
    return (
        <div className="appellate-info-box">
            <h2>Circuit-specific formatting rules for submissions to the United States Courts of Appeals</h2>
            <p>We try our best to keep this page up to date, but Circuit courts may update or change their formatting rules.</p>
            <p>Always verify information presented here independently.</p>
        </div>
    );
}

function SearchBox() {
    return (
        <div className="appellate-search-box">
            search here
        </div>
    );
}