import React from 'react';
import './App.css';

import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Link
} from "react-router-dom";

import Home from "./components/Home";
import FeatureRequest from "./components/FeatureRequest";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
        <a href="/" className="logo-home-link">
          <span>⚖️ LegalEase.co</span>
        </a>
          
        </div>
        <a href="feedback"
           className="feature-request-link"
        >💡 Request a Feature
        </a>
      </header>
      
      <AppContainer />

      <footer className="App-footer">
        <p>Information presented here for recreational purposes only.</p>
        <p>Provided as-is with no warranty. Information might be out of date.</p>
        <p>District maps referenced from Wikipedia.</p>
        <p>Got Feedback? <a href="feedback" style={{color: "#ffffff"}}>Let us know.</a></p>
      </footer>
    </div>
  );
}

export default App;

function AppContainer() {
  return (
    <div className="App-container">
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/feedback" element={<FeatureRequest />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div className="notfound-container">
      <h1>This is not the page you're looking for</h1>
      <iframe title="Page Not found" src="https://giphy.com/embed/l2JJKs3I69qfaQleE" width="480" height="218" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
      <Link to="/">Go Home</Link>
    </div>
  );
}
