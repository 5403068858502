

export class District {
    constructor(name: string) {
        this.name = name;
    }

    name: string;
}

export class CourtFormat {
    constructor(title: string,
        url: string,
        img_url: string,
        description: string,
        date_updated: Date,
        districts: District[]) {
        this.title = title;
        this.url = url;
        this.img_url = img_url;
        this.description = description;
        this.date_updated = date_updated;
        this.districts = districts;
    }

    title: string;
    url: string;
    img_url: string;
    description: string;
    date_updated: Date;
    districts: District[];
}