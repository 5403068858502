function FeatureRequest() {
    return (
        <div className="feature-request-box">
            <p>Got feedback for us? Please email <a href="mailto:feedback@legalease.co">feedback@legalease.co</a>.</p>
            <br></br>
            <a href="/">Go back</a>
        </div>
    );
}

export default FeatureRequest;