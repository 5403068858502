import { CourtFormat, District } from "./types";


function generateFirstCircuit() {
    return new CourtFormat(
    /* title */ "The First Circuit",
    /* url */ "https://www.ca1.uscourts.gov/sites/ca1/files/checklist_for_briefs.pdf",
    /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/1st_Circuit_map.svg/440px-1st_Circuit_map.svg.png",
    /* desc */ "Description for this",
    /* date */ new Date(),
    /* districts */ [new District("District of Maine"),
                     new District("District of Massachusetts"),
                     new District("District of New Hampshire"),
                     new District("District of Puerto Rico"),
                     new District("District of Rhode Island")
                    ]
    );
}

function generateSecondCircuit() {
    return new CourtFormat(
        /* title */ "The Second Circuit",
        /* url */ "https://www.ca2.uscourts.gov/clerk/case_filing/rules/title7/rule_32.html",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/2nd_Circuit_map.svg/440px-2nd_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("District of Connecticut"),
                         new District("Eastern District of New York"),
                         new District("Northern District of New York"),
                         new District("Southern District of New York"),
                         new District("Western District of New York"),
                         new District("District of Vermont"),
                        ]
        );
}

function generateThirdCircuit() {
    return new CourtFormat(
        /* title */ "The Third Circuit",
        /* url */ "https://www.ca3.uscourts.gov/sites/ca3/files/chart%20of%20requirements%20for%20briefs.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7f/3rd_Circuit_map.svg/440px-3rd_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("District of Delaware"),
                         new District("District of New Jersey"),
                         new District("Eastern District of Pennsylvania"),
                         new District("Middle District of Pennsylvania"),
                         new District("Western District of Pennsylvania"),
                        ]
        );
}
function generateFourthCircuit() {
    return new CourtFormat(
        /* title */ "The Fourth Circuit",
        /* url */ "https://www.ca4.uscourts.gov/docs/pdfs/briefapxreq_ca4.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/4th_Circuit_map.svg/440px-4th_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("District of Maryland"),
                         new District("Eastern District of North Carolina"),
                         new District("Middle District of North Carolina"),
                         new District("Western District of North Carolina"),
                         new District("District of South Carolina"),
                         new District("Eastern District of Virginia"),
                         new District("Western District of Virginia"),
                         new District("Northern District of West Virginia"),
                         new District("Southern District of West Virginia"),
                        ]
        );
}
function generateFifthCircuit() {
    return new CourtFormat(
        /* title */ "The Fifth Circuit",
        /* url */ "https://www.ca5.uscourts.gov/docs/default-source/forms-and-documents---clerks-office/rules/brchecklist.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/5th_Circuit_map.svg/440px-5th_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("Eastern District of Louisiana"),
                         new District("Middle District of Louisiana"),
                         new District("Western District of Louisiana"),
                         new District("Northern District of Mississippi"),
                         new District("Southern District of Mississippi"),
                         new District("Eastern District of Texas"),
                         new District("Northern District of Texas"),
                         new District("Southern District of Texas"),
                         new District("Western District of Texas"),
                        ]
        );
}
function generateSixthCircuit() {
    return new CourtFormat(
        /* title */ "The Sixth Circuit",
        /* url */ "https://www.ca6.uscourts.gov/sites/ca6/files/documents/forms/Briefs%20Checklist_0.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/6th_Circuit_map.svg/440px-6th_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("Eastern District of Kentucky"),
                         new District("Western District of Kentucky"),
                         new District("Eastern District of Michigan"),
                         new District("Western District of Michigan"),
                         new District("Northern District of Ohio"),
                         new District("Southern District of Ohio"),
                         new District("Eastern District of Tennessee"),
                         new District("Middle District of Tennessee"),
                         new District("Western District of Tennessee"),
                        ]
        );
}
function generateSeventhCircuit() {
    return new CourtFormat(
        /* title */ "The Seventh Circuit",
        /* url */ "https://www.ca7.uscourts.gov/forms/check.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/7th_Circuit_map.svg/440px-7th_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("Central District of Illinois"),
                         new District("Northern District of Illinois"),
                         new District("Southern District of Illinois"),
                         new District("Northern District of Indiana"),
                         new District("Southern District of Indiana"),
                         new District("Southern District of Indiana"),
                         new District("Eastern District of Wisconsin"),
                         new District("Western District of Wisconsin"),
                        ]
        );
}
function generateEighthCircuit() {
    return new CourtFormat(
        /* title */ "The Eighth Circuit",
        /* url */ "https://ecf.ca8.uscourts.gov/newcoa/forms/pointer8.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/8th_Circuit_map.svg/440px-8th_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("Eastern District of Arkansas"),
                         new District("Western District of Arkansas"),
                         new District("Northern District of Iowa"),
                         new District("Southern District of Iowa"),
                         new District("District of Minnesota"),
                         new District("Eastern District of Missouri"),
                         new District("Western District of Missouri"),
                         new District("District of Nebraska"),
                         new District("District of North Dakota"),
                         new District("District of South Dakota"),
       ]
);
}
function generateNinthCircuit() {
    return new CourtFormat(
        /* title */ "The Ninth Circuit",
        /* url */ "https://cdn.ca9.uscourts.gov/datastore/uploads/guides/Shell_Brief.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/9th_Circuit_map.svg/440px-9th_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("District of Alaska"),
                         new District("District of Arizona"),
                         new District("Central District of California"),
                         new District("Eastern District of California"),
                         new District("Northern District of California"),
                         new District("Southern District of California"),
                         new District("District of Hawaii"),
                         new District("District of Idaho"),
                         new District("District of Montana"),
                         new District("District of Nevada"),
                         new District("District of Oregon"),
                         new District("Eastern District of Washington"),
                         new District("Western District of Washington"),
                         new District("District of Guam"),
                         new District("District of the Northern Mariana Islands"),
                        ]
        );
}

function generateTenthCircuit() {
    return new CourtFormat(
        /* title */ "The Tenth Circuit",
        /* url */ "https://www.ca10.uscourts.gov/sites/ca10/files/documents/downloads/brief%20and%20appendix%20checklist%202020.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/10th_Circuit_map.svg/440px-10th_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("District of Colorado"),
                         new District("District of Kansas"),
                         new District("District of New Mexico"),
                         new District("Eastern District of Oklahoma"),
                         new District("Northern District of Oklahoma"),
                         new District("Western District of Oklahoma"),
                         new District("District of Utah"),
                         new District("District of Wyoming"),
                        ]
        );
}
function generateEleventhCircuit() {
    return new CourtFormat(
        /* title */ "The Eleventh Circuit",
        /* url */ "https://www.ca11.uscourts.gov/sites/default/files/courtdocs/clk/Sample_Shell_Brief_Final.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/1st_Circuit_map.svg/440px-1st_Circuit_map.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [new District("Middle District of Alabama"),
                         new District("Northern District of Alabama"),
                         new District("Southern District of Alabama"),
                         new District("Middle District of Florida"),
                         new District("Northern District of Florida"),
                         new District("Southern District of Florida"),
                         new District("Middle District of Georgia"),
                         new District("Northern District of Georgia"),
                         new District("Southern District of Georgia"),
                        ]
        );
}
function generateDCCircuit() {
    return new CourtFormat(
        /* title */ "The District of Columbia Circuit",
        /* url */ "https://www.cadc.uscourts.gov/internet/home.nsf/content/VL%20-%20RPP%20-%20Brief%20Compliance%20Checklist%20for%20Pro%20Se%20and%20Attorney%20Filers/$FILE/Brief%20Compliance%20Checklist.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/d/d2/DC_locator_map_with_state_names_w_usmap.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ [
            new District("District of Columbia"),
        ]
        );
}

function generateFederalCircuit() {
    return new CourtFormat(
        /* title */ "The Federal Circuit",
        /* url */ "https://cafc.uscourts.gov/wp-content/uploads/RulesProceduresAndForms/FederalCircuitRules/FederalCircuitRulesofPractice.pdf",
        /* img_url */ "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Seal_of_the_United_States_Court_of_Appeals_for_the_Federal_Circuit.svg/300px-Seal_of_the_United_States_Court_of_Appeals_for_the_Federal_Circuit.svg.png",
        /* desc */ "Description for this",
        /* date */ new Date(),
        /* districts */ []
        );
}

export function generateAppellateCourtFormats() {
    return [
        generateFirstCircuit(),
        generateSecondCircuit(),
        generateThirdCircuit(),
        generateFourthCircuit(),
        generateFifthCircuit(),
        generateSixthCircuit(),
        generateSeventhCircuit(),
        generateEighthCircuit(),
        generateNinthCircuit(),
        generateTenthCircuit(),
        generateEleventhCircuit(),
        generateDCCircuit(),
        generateFederalCircuit()
    ];
}